import React from 'react'

import useMediaQuery from '../../../hooks/layout/useMediaQuery'
import Parallax from '../../animation/Parallax'
import { HeroImgMac, HeroImgIOS } from './HeroImg'


import { IMG_PATH } from '../../../constants/paths'


const Hero = React.forwardRef(({ }, ref) => {
    const { pc } = useMediaQuery()

    return (
        <section ref={ref} className="hero">
            <div className="hero__header">
                <img className='hero__logo' src={IMG_PATH + 'logo/cinefiled_logo.png'} alt="Ciinefiled Logo" />
                <h2 className='hero__catch'>Your digital cinema companion</h2>
            </div>
            <div className="hero__body-wrapper">
                <div className="hero__body">
                    <Parallax speed={0.1}>
                        {HeroImgMac}
                    </Parallax>
                    <Parallax speed={0.2} maxOffset={pc ? 30 : 100}>
                        {HeroImgIOS}
                    </Parallax>
                </div>
            </div>
        </section>
    )
})

export default Hero


