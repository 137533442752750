import React from 'react'
import { useSelector } from 'react-redux'
import useMediaQuery from '../../hooks/layout/useMediaQuery'
import useScrollDirection from '../../hooks/position/useScrollDirection'
import AnimateIn from '../animation/AnimateIn'
import { IMG_PATH } from '../../constants/paths'
import { PLATFORM } from '../../constants/platform-data'

const AppStore = () => {
    const direction = useScrollDirection()
    const { autoHideMenu } = useSelector(state => state.ui)

    return (
        <section className={`appstore${(direction == 'down' || !autoHideMenu) ? ' visible' : ' hidden'}`}>
            <AppStoreLink platform={PLATFORM.macos} />
            <AppStoreLink platform={PLATFORM.ios} />
        </section>
    )
}

const AppStoreLink = ({ platform }) => {

    const { key, appStoreLink } = platform
    const { pc, tab } = useMediaQuery()
    const animationProps = (pc || tab) ? { initialOffset: '4rem' } : {};
    return (
        <AnimateIn {...animationProps}>
            <a className='appstore__link' href={appStoreLink}>
                <img className={`appstore__device appstore__device--${key}`} src={IMG_PATH + `appstore/device-icon_${key}_white-bkg.svg`} alt="" />
                <img className='appstore__link-btn' src={IMG_PATH + `appstore/app-store_${key}.svg`} alt="" />
            </a>
        </AnimateIn>
    )
}

export default AppStore

