import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { uiReducer } from './reducers/uiReducers';

const reducer = combineReducers({
  ui: uiReducer,
});
const store = configureStore({ reducer });

export default store;
