export const PLATFORM = {
  ios: {
    key: 'ios',
    appName: 'Cinefiled: Now Playing',
    appStoreLink:
      'https://apps.apple.com/app/cinefiled-now-playing/id1667075165',
  },
  macos: {
    key: 'macos',
    appName: 'Cinefiled',
    appStoreLink: 'https://apps.apple.com/app/cinefiled/id6448912043',
  },
};
