import React, { useEffect, useRef, useState } from 'react'
import useScrollObserver from '../../hooks/position/useScrollObserver'


const Parallax = ({ speed, children, maxOffset = 100 }) => {
    const ref = useRef()
    const { isVisible, windowScroll, startingPosition } = useScrollObserver(ref)
    const [offset, setOffset] = useState(0)
    const [initialPosition, setInitialPosition] = useState(0)


    useEffect(() => {
        if (ref.current) {

            setInitialPosition(ref.current.offsetTop)
        }
    }, [ref])


    useEffect(() => {
        if (isVisible) {
            let newOffset = Math.min((windowScroll - initialPosition) * speed, maxOffset)

            setOffset(newOffset)
        }
    }, [isVisible, windowScroll, initialPosition, speed, maxOffset])

    return (
        <>
            {React.Children.map(children, child =>
                React.cloneElement(child, {
                    ref: ref,
                    style: { ...child.props.style, transform: `translateY(${offset}px)` }
                })
            )}
        </>
    )
}

export default Parallax