import React from 'react'

const SceneSection = React.forwardRef(({ className = '', style, children }, ref) => {
    return (
        <section ref={ref} className={`scene-section ${className}`.trim()} style={style}>
            <div className="scene-section__content">
                {children}

            </div>
        </section>
    )
})

export default SceneSection