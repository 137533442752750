import React from 'react'
import ReactDOM from 'react-dom';

import { IMG_PATH } from '../../constants/paths'

const FilmBkg = () => {

    const portalElement = document.getElementById('bkg-portal')
    return (

        portalElement ? ReactDOM.createPortal(
            <div className="film-bkg__wrapper">
                <div className='film-bkg'>
                    <img src={IMG_PATH + 'design/film_bkg.png'} alt="" />
                </div>
            </div>,
            document.getElementById('bkg-portal')
        )
            : null
    )
}

export default FilmBkg