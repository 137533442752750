import React from 'react'
import { useSpring, animated, useInView } from 'react-spring'
import Callout from './Callout'
import { COLOR } from '../../constants/colors'

const AnimatedCallout = ({ children, animationPercentage, className, style = {}, pointPosition = 'bottom', pointOffset = '2rem', pointAlignment = 'center' }) => {
    const castProps = useSpring({
        transform: `scale(${animationPercentage})`,
        config: { tension: 500, friction: 60 },
    })
    return (
        <animated.div className={`animated-callout${className ? ' ' + className : ''}`} style={{ ...castProps, ...style }}>

            <Callout borderColor={COLOR.YELLOW.color} backgroundColor={COLOR.BLACK.color} {...{ pointPosition, pointOffset, pointAlignment }}>
                <div className="animated-callout__content">
                    {children}
                </div>
            </Callout>
        </animated.div>
    )
}

export default AnimatedCallout