import React, { useRef } from 'react'
import { useSpring, animated, useInView } from 'react-spring'

import useScrollAnimation from '../../../hooks/position/useScrollAnimation'

import AnimateIn from '../../animation/AnimateIn'
import SceneSection from '../../layout/SceneSection'


import { IMG_PATH } from '../../../constants/paths'

const PCDetail = () => {
    const ref = useRef(null)

    const { animationPercentage, containerProps } = useScrollAnimation(ref, 2)

    const sukushoProps = useSpring({
        transform: `scale(${1 + animationPercentage * 0.3})`,
        config: { tension: 500, friction: 50 },
        from: { transformOrigin: 'right' }
    })


    return (
        <SceneSection ref={ref} className='pc-detail' style={containerProps}>

            <div className="pc-detail__text">
                <AnimateIn direction='right'>
                    <h2>Choose the perfect film!</h2>
                </AnimateIn>
                <AnimateIn direction='right' delay={500}><p className='glow'>View movie details, add custom tags, and start watching right away using your preferred player!</p></AnimateIn>
            </div>
            <AnimateIn direction='left' tension={100}>
                <div>
                    <animated.div style={sukushoProps} className="pc-detail__sukusho-wrapper">

                        <img className='pc-detail__sukusho' src={IMG_PATH + 'screenshots/detail-view_pc.png'} alt="Movie Details" />

                    </animated.div>
                </div>
            </AnimateIn>
        </SceneSection>

    )
}

export default PCDetail