import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import useMediaQuery from '../../../hooks/layout/useMediaQuery'
import useScrollAnimation from '../../../hooks/position/useScrollAnimation'

import SceneSection from '../../layout/SceneSection'
import SwipeScene from '../../layout/SwipeScene'
import PCWatch from '../pc/PCWatch'
import IOSPhone from './IOSPhone'

import { ui } from '../../../constants/redux'
import IOSWatch from './IOSWatch'

const IOSScene = () => {
    const ref = useRef(null)
    const { pc } = useMediaQuery()
    const dispatch = useDispatch()
    const { containerProps, currentScene, getAnimationPercentage } = useScrollAnimation(ref, [6, 4, 4])

    useEffect(() => {
        if (!pc) dispatch({ type: ui.AUTO_HIDE_MENU, payload: currentScene !== 1 })
    }, [currentScene, pc])

    return (
        <SceneSection className='ios-scene' ref={ref} style={containerProps}>
            <SwipeScene transitionType={['swipe', 'fade']} direction='vertical' currentScene={currentScene} getAnimationPercentage={getAnimationPercentage}>
                <PCWatch />
                <IOSPhone />
                <IOSWatch />
            </SwipeScene>
        </SceneSection>
    )
}

export default IOSScene