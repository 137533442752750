import React from 'react'
import { IMG_PATH } from '../../../constants/paths'

export const HeroImgMac = <div className="hero__app macos">
  <img className="hero__ss macos" src={IMG_PATH + 'screenshots/macos-hero.png'} alt="" />
</div>



export const HeroImgIOS = <div className="hero__app ios">

  <img className="hero__ss ios" src={IMG_PATH + 'screenshots/ios-1.png'} alt="" />

  <img className="hero__ss watchos" src={IMG_PATH + 'screenshots/watchos-1.png'} alt="" />

</div>


