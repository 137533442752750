import React, { useRef } from 'react'
import useMediaQuery from '../../../hooks/layout/useMediaQuery'
import useScrollAnimation from '../../../hooks/position/useScrollAnimation'
import SceneSection from '../../layout/SceneSection'
import Footer from '../../layout/Footer'
import { HeroImgIOS, HeroImgMac } from './HeroImg'
import AnimateIn from '../../animation/AnimateIn'

import { IMG_PATH } from '../../../constants/paths'


const Antihero = () => {
    const ref = useRef(null)
    const { pc } = useMediaQuery()
    const { containerProps } = useScrollAnimation(ref, 2)
    return (
        <SceneSection ref={ref} style={containerProps} className='hero antihero'>
            <div className="hero__header">
                <AnimateIn delay={200}>
                    <img className='hero__logo' src={IMG_PATH + 'logo/cinefiled_logo.png'} alt="Ciinefiled Logo" />
                </AnimateIn>
                <AnimateIn delay={400}>
                    <h2 className='hero__catch'>Download it today for macOS and iOS!</h2>
                </AnimateIn>
            </div>
            <AnimateIn delay={600}>
                <div className="hero__body">
                    {HeroImgMac}
                    {HeroImgIOS}
                </div>
            </AnimateIn>
            {!pc && <Footer />}
        </SceneSection>

    )
}

export default Antihero