import { useEffect, useState } from 'react'

import useScrollObserver from './useScrollObserver'
import useElementSize from '../layout/useElementSize'

const useScrollAnimation = (ref, scenes = 2, log) => {


    const multipleScenes = Array.isArray(scenes) && scenes.length > 0
    let length = multipleScenes ? scenes.slice(0, -1).concat(scenes[scenes.length - 1] + 1) : scenes + 1
    const sceneTotal = multipleScenes ? length.reduce((total, current) => total + current, 0) : length



    const { height: totalHeight } = useElementSize(ref)
    const { posFromTop } = useScrollObserver(ref)
    const [animationPercentage, setAnimationPercentage] = useState(0)
    const [currentScene, setCurrentScene] = useState(0)



    useEffect(() => {

        const heightPerUnit = totalHeight / sceneTotal

        const currentScreenheight = (multipleScenes ? heightPerUnit * (length[currentScene]) : totalHeight)

        const start = multipleScenes ? length.slice(0, currentScene).reduce((total, current) => total + current, 0) * heightPerUnit : 0

        const maxAnimation = currentScreenheight + start

        if (maxAnimation > 0) {

            //calculate new percentage
            const finalAdjust = (currentScene === length.length - 1 || !multipleScenes) ? heightPerUnit : 0
            const newPercentage = (posFromTop - start) / (maxAnimation - finalAdjust - start)

            //change scene if necessary
            if (multipleScenes) {
                if (newPercentage > 1) {
                    if (length.length - 1 === currentScene) {
                        setAnimationPercentage(1)
                    } else {
                        setCurrentScene(pVal => length.length > pVal + 1 ? pVal + 1 : pVal)
                    }

                } else if (newPercentage < 0) {

                    setCurrentScene(pVal => pVal > 0 ? pVal - 1 : 0)
                } else {

                    setAnimationPercentage(newPercentage)
                }
            } else {
                setAnimationPercentage(Math.min(Math.max(newPercentage, 0), 1))
            }

        }
    }, [totalHeight, posFromTop, multipleScenes, currentScene])



    const isWithinRange = (scene1, scene2, animationPercentage1, animationPercentage2) => {
        const ap1 = animationPercentage1 ?? 0
        const ap2 = animationPercentage2 ?? 1

        if (currentScene < scene1 || currentScene > scene2) return false

        if (currentScene === scene1) return animationPercentage >= ap1

        if (currentScene === scene2) return animationPercentage <= ap2
    }

    const getAnimationPercentage = (start, end = 1, scene = 0, stickAround = true) => {
        if (scene > currentScene) return 0

        if (stickAround && scene < currentScene) return 1
        if (log) console.log(`current scene is ${currentScene} per is ${Math.min(Math.max((animationPercentage - start) / (end - start), 0), 1)}`);

        return Math.min(Math.max((animationPercentage - start) / (end - start), 0), 1)
    }

    return { animationPercentage, getAnimationPercentage, containerProps: { height: `${sceneTotal * 100}vh` }, currentScene, isWithinRange }

}

export default useScrollAnimation