import { useState, useEffect } from "react";
import useOnScreen from "./useOnScreen";


const useScrollObserver = (ref, topThreshold = 5, log) => {
    const isVisible = useOnScreen(ref, log);
    const [windowScroll, setWindowScroll] = useState(window.scrollY);
    const [startingPosition, setStartingPosition] = useState(null);
    const [isTop, setIsTop] = useState(false)
    const [posFromTop, setPosFromTop] = useState(0)

    useEffect(() => {

        const scrollHandler = () => {
            setWindowScroll(window.scrollY);
            if (ref.current) {

                const rect = ref.current.getBoundingClientRect();
                const top = Math.abs(rect.top) <= topThreshold

                if (window.scrollY >= ref.current.offsetTop) {

                    setPosFromTop(window.scrollY - ref.current.offsetTop)
                }

                setIsTop(top); // checks if the top position is within the threshold
            }
        }

        if (isVisible) {

            setStartingPosition(window.scrollY);
            window.addEventListener('scroll', scrollHandler)

        } else {

            setStartingPosition(null);
            setWindowScroll(window.scrollY)
            setIsTop(false)
            // window.removeEventListener('scroll', scrollHandler)
        }

        return () => { window.removeEventListener('scroll', scrollHandler) }

    }, [isVisible])

    return {
        windowScroll,
        startingPosition,
        isVisible,
        isTop,
        posFromTop
    }
}

export default useScrollObserver;