import React from 'react'
import { useSpring } from 'react-spring'

import useMediaQuery from '../../../hooks/layout/useMediaQuery'

import AnimateIn from '../../animation/AnimateIn'
import CrossfadeImg from '../../animation/CrossfadeImg'
// import { IMG_PATH } from '../../../constants/paths'



const IOSPhone = ({ getAnimationPercentage }) => {
    const animationPercentage = getAnimationPercentage(0, 1)
    const changeOver = 0.7
    const { pc } = useMediaQuery()

    const phoneProps = useSpring({
        scale: animationPercentage >= changeOver ? 1 + (getAnimationPercentage(changeOver, 0.9) * 0.1) : 1.1 - (getAnimationPercentage(0.4, changeOver) * 0.1),

        config: { tension: 170, friction: 26 }

    })

    return (
        <div className='ios-phone'>
            <div className="ios-phone__header">

                <div className="ios-phone__title">
                    {animationPercentage > changeOver ? <AnimateIn key='profiles' direction='up' >
                        <h2 >In-depth profiles!</h2>
                    </AnimateIn> :
                        <AnimateIn key='cast' direction='up' delay={200} >
                            <h2 >Easily find cast & crew!</h2>
                        </AnimateIn>

                    }
                </div>
                <div className="ios-phone__desc">
                    {animationPercentage > changeOver ? <AnimateIn key='profile-txt' direction='up' delay={400}>
                        <p className='glow'>Simply click on their profile to unveil their entire filmography and learn more about their career!</p>
                    </AnimateIn> :
                        <AnimateIn key='cast-txt' direction='up' delay={600} >
                            <p className='glow'>Instantly access information on the actors, directors, and crew members involved in your favorite movies and TV shows with just a few taps.</p>
                        </AnimateIn>
                    }
                </div>
                <div className="ios-phone__text ios-phone__text--2"></div>
            </div>
            <div className="ios-phone__sukusho">
                {/* <img src={IMG_PATH + 'screenshots/ios_film.png'} alt="" /> */}
                <CrossfadeImg
                    style={phoneProps}
                    className='ios-phone__sukusho-wrapper'
                    animationPercentage={getAnimationPercentage(0.6, changeOver)}
                    src1='screenshots/ios_film.png'
                    src2='screenshots/ios_profile.png'
                    srcBKG={'screenshots/ios_blank.png'} />
            </div>
        </div>
    )
}

export default IOSPhone