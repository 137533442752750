import React from 'react'
import { animated, useSprings } from 'react-spring'
import { numOccurrances } from '../../util/conveniences'

const SwipeScene = ({ currentScene, transitionType = 'swipe', direction = 'horizontal', start = 0, end, tension = 170, friction = 26, config = {}, children, getAnimationPercentage = () => { } }) => {

    const numChildren = React.Children.count(children)
    const adjScene = Math.min(Math.max(currentScene - start, 0), end ?? numChildren - 1)

    const springs = useSprings(
        numChildren,
        Array.from({ length: numChildren }, (_, i) => {
            let opacity = 1
            let translate = 0

            //HANDLE ARRAY
            if (Array.isArray(transitionType)) {

                //HANDLE OPACITY
                if ((transitionType[i - 1] === 'fade' && i > adjScene) || transitionType[i] === 'fade' && i < adjScene) opacity = 0
                //HANDLE TRANSLATE
                translate = (numOccurrances(transitionType, 'swipe', i) - numOccurrances(transitionType, 'swipe', adjScene)) * 100

            } else if (transitionType === 'swipe') {
                translate = (i - adjScene) * 100
            } else if (transitionType === 'fade') {
                opacity = i === adjScene ? 1 : 0
            }

            return {
                opacity: opacity,
                transform: `translate${direction === 'vertical' ? 'Y' : 'X'}(${translate}%)`,
                config: { tension, friction, ...config },
            }
        }
        )
    )



    return (
        <div className='swipe-scene'>
            {
                React.Children.map(children, (child, i) => {
                    return (

                        <animated.div style={{ ...springs[i] }} key={i}>
                            {React.cloneElement(child, { getAnimationPercentage: (beg, end = 1, stickAround = true) => getAnimationPercentage(beg, end, i + start, stickAround) })}
                        </animated.div>
                    )
                }
                )}
        </div>
    )
}

export default SwipeScene