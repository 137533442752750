import { Route, Routes, Navigate } from 'react-router-dom';

import HomeScreen from './screens/HomeScreen'
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import AnimationTest from './components/AnimationTest';
import Footer from './components/layout/Footer'
import AppStore from './components/sections/AppStore';

import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';



function App() {
  const location = useLocation()
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");
  const [portalPresent, setPortalPresent] = useState(false)

  useEffect(() => {
    setPortalPresent(true)
  }, [])

  useEffect(() => {
    if (location.pathname !== displayLocation.pathname) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

  return (
    <>
      <div id="bkg-portal" />

      {portalPresent && <main className={transitionStage} onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransistionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}>

        <Routes key='route-key'
          location={displayLocation}
        >
          <Route path='/' element={<HomeScreen />} key={'home'} />
          <Route path='/test' element={<AnimationTest />} key={'home'} />
          <Route path='/privacy' element={<PrivacyPolicyScreen />} key={'privacy'} />
          <Route path="*" element={<HomeScreen />} key={'rehome'} />
        </Routes>

      </main >}
      <AppStore />

    </>

  );
}

export default App;
