import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useInView } from 'react-spring'
import useMediaQuery from '../hooks/layout/useMediaQuery'
import FilmBkg from '../components/design/FilmBkg'
import Hero from '../components/sections/hero/Hero'
import PCBrowse from '../components/sections/pc/PCBrowse'
import PCDetail from '../components/sections/pc/PCDetail'
import PCFinder from '../components/sections/pc/PCFinder'
import IOSScene from '../components/sections/ios/IOSScene'
import Antihero from '../components/sections/hero/Antihero'
import Footer from '../components/layout/Footer'

import { ui } from '../constants/redux'



const HomeScreen = () => {
    const { pc, ssp, tab } = useMediaQuery()

    const dispatch = useDispatch()
    const [ref, inView] = useInView()

    useEffect(() => {

        if (!ssp) dispatch({ type: ui.AUTO_HIDE_MENU, payload: (pc || tab) ? inView : !inView })

    }, [inView, pc, ssp])




    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <FilmBkg />
            <div
                className="home"
            >

                <Hero ref={ref} />

                <PCBrowse />
                <PCDetail />
                <PCFinder />
                <IOSScene />
                <Antihero />
                {pc && <Footer />}

            </div>

        </>
    )
}

export default HomeScreen