import React, { useRef } from 'react'
import { useSpring, animated, useInView } from 'react-spring'

import useMediaQuery from '../../../hooks/layout/useMediaQuery'


import useScrollAnimation from '../../../hooks/position/useScrollAnimation'
import AnimateIn from '../../animation/AnimateIn'
import SceneSection from '../../layout/SceneSection'

import { IMG_PATH } from '../../../constants/paths'


const PCBrowse = () => {
  const ref = useRef(null)
  const { pc, tab, ssp } = useMediaQuery()


  const { getAnimationPercentage, containerProps } = useScrollAnimation(ref, pc ? 2.5 : 3)
  const sukushoAP = getAnimationPercentage(0.4, 0.7)

  const [pcRef, pcInView] = useInView()

  const sukushoProps = useSpring({
    transform: `translateX(${tab ? (sukushoAP * -65) + 15 : sukushoAP * -50}%)`,
    config: { tension: 500, friction: 50 },
    immediate: !pcInView
  })

  const pcProps = useSpring({
    opacity: pcInView ? 1 : 0,
    config: { tension: 200, friction: 100 },
  })

  const searchProps = useSpring({
    transform: `scale(${getAnimationPercentage(0.1, pc ? 0.15 : 0.2)})`,
    config: { tension: 500, friction: 60 },
  })

  const filterProps = useSpring({
    transform: `scale(${getAnimationPercentage(pc ? 0.6 : 0.75, pc ? 0.7 : 0.8)})`,
    config: { tension: 500, friction: 60 },
  })

  return (
    <SceneSection ref={ref} className='pc-browse' style={containerProps}>

      <div className="pc-browse__catch">
        <AnimateIn >
          <h2>Easily browse your digital movie collection!</h2>
        </AnimateIn>
        {!pc && !ssp && (
          <AnimateIn delay={500}>
            <p>Filter by unwatched, genre, cast, director, runtime, custom tags and more! Find the perfect movie, every time! </p>
          </AnimateIn>
        )}
      </div>
      <animated.div className={`pc-browse__image`} style={pc ? {} : sukushoProps}>
        <animated.img style={searchProps} className={`pc-browse__popup pc-browse__popup--search`} src={IMG_PATH + 'screenshots/popup_search.png'} alt="" />
        <animated.img style={filterProps} className='pc-browse__popup pc-browse__popup--filter' src={IMG_PATH + 'screenshots/popup_ez-filters.png'} alt="" />
        <animated.img ref={pcRef} style={pcProps} className='pc-browse__sukusho' src={IMG_PATH + 'screenshots/front-view_pc.png'} alt="" />
      </animated.div>

    </SceneSection>
  )
}

export default PCBrowse