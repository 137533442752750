import Reactfrom from 'react'
import { useSpring, animated } from 'react-spring'

import useMediaQuery from '../../../hooks/layout/useMediaQuery'

import AnimateIn from '../../animation/AnimateIn'
import CrossfadeImg from '../../animation/CrossfadeImg'
import AnimatedCallout from '../../design/AnimatedCallout'

import { IMG_PATH } from '../../../constants/paths'


const PCWatch = ({ getAnimationPercentage }) => {
    const { pc, tab } = useMediaQuery()
    const watchChange = 0.35
    const changeStart = 0.6
    const changeEnd = 0.7
    const changeAnimation = getAnimationPercentage(changeStart, changeEnd)
    const watchScaleAnimation = getAnimationPercentage(0.8, 0.85)
    const pcOffset = 60




    const textProps = useSpring({
        transform: `translateX(${changeAnimation * -100}%)`,
        config: { tension: 170, friction: 26 }
    })

    const logoProps = useSpring({
        transform: `translateX(${100 - changeAnimation *
            100}%)`,
        config: { tension: 170, friction: 26 }
    })
    const macosProps = useSpring({
        transform: `translateX(${changeAnimation * (-100 - pcOffset)}%)`,
        config: { tension: 170, friction: 26 }
    })
    const iosProps = useSpring({
        transform: `translateX(${(-140 - pcOffset) + changeAnimation * ((tab ? 160 : 140) + pcOffset)}%)`,
        config: { tension: 170, friction: 26 }
    })
    const watchosProps = useSpring({
        transform: `scale(${Math.max(1 - watchScaleAnimation, 0.8)}) translate(-${tab ? 0 : watchScaleAnimation * 8}rem, ${watchScaleAnimation * (tab ? 6 : 8)}rem)`,

        config: { tension: 170, friction: 26 }
    })

    const watchLogo = (
        <animated.div className='pc-watch__logo' style={{ ...(pc ? logoProps : textProps) }}>
            <img src={IMG_PATH + 'logo/ios-logo.png'} />
        </animated.div>
    )


    return (
        <div className='pc-watch'>
            <div className="pc-watch__header">
                <animated.div className="pc-watch__text" style={{ ...textProps }}>
                    <AnimateIn direction='right' delay={200} stickAround={true}>
                        <h2>Seemless syncing with the iOS companion app!</h2>
                    </AnimateIn>
                    <AnimateIn direction='right' delay={500} stickAround={true}>
                        <p>Just hit play and you're ready to roll!</p>
                    </AnimateIn>
                </animated.div>
                {!pc && watchLogo}
            </div>
            {pc && watchLogo}
            <div className="pc-watch__sukusho">

                <animated.div className="pc-watch__image pc-watch__image--macos" style={{ ...macosProps }}>
                    <img src={IMG_PATH + 'screenshots/macos_movie.png'} alt="" />
                </animated.div>
                <animated.div className="pc-watch__image pc-watch__image--ios" style={{ ...iosProps }}>
                    <img src={IMG_PATH + 'screenshots/ios-1.png'} alt="" />
                </animated.div>
                <div className="pc-watch__image pc-watch__image--watchos">
                    <AnimatedCallout
                        animationPercentage={getAnimationPercentage(0, 1) < changeStart ? getAnimationPercentage(0.05, 0.08) : 1 - getAnimationPercentage(0.75, 0.8)}
                        className='pc-watch__popup pc-watch__popup--cast'
                        pointPosition='top'
                        pointAlignment={pc ? 'center' : 'end'}
                        pointOffset='8rem'
                    >
                        <p>View the <span className='em'>cast & crew</span> of the film you're watching on your Apple Watch® without having to search!</p>
                    </AnimatedCallout>
                    <AnimatedCallout
                        animationPercentage={getAnimationPercentage(0, 1) < changeStart ? getAnimationPercentage(watchChange, 0.38) : 1 - getAnimationPercentage(0.75, 0.8)}
                        className='pc-watch__popup pc-watch__popup--vlc'
                    >
                        <p>Control <span className='em'>VLC</span> right from your wrist!</p>
                    </AnimatedCallout>

                    <CrossfadeImg
                        style={{ ...(watchosProps) }}
                        animationPercentage={getAnimationPercentage(0, 1) < 0.75 ? getAnimationPercentage(0.3, watchChange) : 1 - getAnimationPercentage(0.75, 0.8)}

                        src1='screenshots/watchos-2.png'
                        src2='screenshots/watchos-2_vlc.png'
                        srcBKG={'screenshots/watchos-2_bkg.png'} />
                </div>

            </div>
        </div>

    )
}

export default PCWatch