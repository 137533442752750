import React from 'react'

const Footer = () => {
    return (
        <footer>
            <p>Support: <a href='mailto:cinefiled@dylansouthard.info'>cinefiled@dylansouthard.info</a></p>
            <p className='sm'>© Dylan Southard {new Date().getFullYear()}</p>
        </footer>
    )
}

export default Footer