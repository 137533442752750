import React from 'react'

const PrivacyPolicyScreen = () => {
  return (
    <main className='privacy-policy'>
      <div className='privacy-policy__head'>
        <h1>Privacy Policy for Cinefiled & Cinefiled: Now Playing</h1>
        <p>We prioritize your privacy. This Privacy Policy describes the types of information we collect, how we record it, and how we use it.</p>
      </div>

      <div className='privacy-policy__body'>
        <div className='privacy-policy__block'>
          <h2>Data Collection</h2>
          <p>We are proud to say that we do not collect or use any personal data in our applications Cinefiled and Cinefiled: Now Playing. Your privacy is our top priority, and as such, we have built our apps to work without storing or processing any user data.</p>
        </div>

        <div className='privacy-policy__block'>
          <h2>Our Use of Cookies</h2>
          <p>Our applications, Cinefiled and Cinefiled: Now Playing, do not use cookies as there is no collection or storage of personal data.</p>
        </div>

        <div className='privacy-policy__block'>
          <h2>Your Rights</h2>
          <p>As we do not collect or process any personal data in our applications, there is no need for concern regarding the access to, correction of, deletion of, or any other actions related to personal data.</p>
        </div>

        <div className='privacy-policy__block'>
          <h2>Security</h2>
          <p>While we do not collect personal data, we take the security of our apps seriously. Our applications are built with robust security measures to provide a safe and secure user experience.</p>
        </div>

        <div className='privacy-policy__block'>
          <h2>Third-Party Privacy Policies</h2>
          <p>Cinefiled and Cinefiled: Now Playing's Privacy Policy does not apply to other services or websites that may be accessed through our apps. We advise you to consult the respective Privacy Policies of these third-party services or websites for more detailed information. They may include their practices and instructions about how to opt-out of certain options.</p>
        </div>

        <div className='privacy-policy__block'>
          <h2>Contact</h2>
          <p>If you have any questions or concerns about our privacy policy, please feel free to contact us.</p>
        </div>

        <div className='privacy-policy__block'>
          <h2>Consent</h2>
          <p>By using our applications, you hereby consent to our Privacy Policy and agree to its terms.</p>
        </div>
      </div>
    </main>
  )
}

export default PrivacyPolicyScreen