import React, { useRef } from 'react'

import useScrollAnimation from '../hooks/position/useScrollAnimation'
import SwipeScene from './layout/SwipeScene'


import SceneSection from './layout/SceneSection'


const AnimationTest = () => {
    const ref = useRef(null)
    const { containerProps, currentScene, getAnimationPercentage, animationPercentage, isWithinRange } = useScrollAnimation(ref, [2, 2, 2])

    const getStyle = (color) => ({
        height: '100%',
        width: '100%',
        backgroundColor: color
    })

    return (
        <SceneSection ref={ref} style={containerProps} >
            <SwipeScene transitionType={['fade', 'swipe']} direction='vertical' currentScene={currentScene} getAnimationPercentage={getAnimationPercentage}>
                <TestDiv color='blue' />
                <TestDiv color='green' />
                <TestDiv color='yellow' />

            </SwipeScene>
            <h1>Scene {currentScene} animation: ${animationPercentage}</h1>
        </SceneSection>
    )
}

export default AnimationTest


export const TestDiv = ({ getAnimationPercentage, color }) => {

    return (
        <div style={{
            height: '100%',
            width: '100%',
            backgroundColor: color
        }}>
            <p style={{ opacity: getAnimationPercentage(0, 1) }}>This will appear</p>
        </div>
    )
}