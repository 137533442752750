import { useState, useEffect } from 'react';

const useElementSize = (ref) => {
    const [size, setSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateSize = () => {
            if (ref.current) {
                setSize({
                    width: ref.current.offsetWidth,
                    height: ref.current.offsetHeight
                });
            }
        }

        window.addEventListener('resize', updateSize);
        updateSize(); // initial size update

        return () => window.removeEventListener('resize', updateSize);
    }, [ref]);

    return size;
}

export default useElementSize;