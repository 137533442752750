import React, { useRef } from 'react'

import useScrollAnimation from '../../../hooks/position/useScrollAnimation'
import useMediaQuery from '../../../hooks/layout/useMediaQuery'
import { useSpring, animated } from 'react-spring'
import AnimateIn from '../../animation/AnimateIn'
import AnimatedCallout from '../../design/AnimatedCallout'
import SceneSection from '../../layout/SceneSection'


import { IMG_PATH } from '../../../constants/paths'

const PCFinder = () => {
    const ref = useRef(null)
    const { pc } = useMediaQuery()


    const { containerProps, getAnimationPercentage } = useScrollAnimation(ref, 3)
    const sukushoAnimationPercentage = getAnimationPercentage(0.2, 0.35)
    const finderProps = useSpring({
        transform: `translate${pc ? 'X' : 'Y'}(${50 - sukushoAnimationPercentage * 50}%)`,
        opacity: sukushoAnimationPercentage,
    })


    return (
        <SceneSection ref={ref} className='pc-finder' style={containerProps}>



            <div className="pc-finder__text">
                <AnimateIn direction='left'>
                    <h2>Organize your collection!</h2>

                </AnimateIn>
                <AnimateIn direction='left' delay={500}>
                    <p>When you add movies to your collection, Cinefiled automatically creates an organized folder with all metadata and genre tags! </p>
                </AnimateIn>
            </div>
            <div className="pc-finder__lower">
                {/* <AnimateIn direction={pc ? 'up' : 'right'} delay={750} stickAround={true}> */}
                <animated.div className="pc-finder__sukusho" style={{ ...finderProps }}>
                    <img src={IMG_PATH + 'screenshots/finder_view.png'} alt="" />

                </animated.div>

                <AnimatedCallout
                    pointPosition='top'
                    animationPercentage={getAnimationPercentage(0.4, 0.6)}
                    className='pc-finder__popup'
                >
                    <p>Folder images are replaced with <span className='em'>movie posters</span> for easy browsing!</p>
                </AnimatedCallout>

            </div>

        </SceneSection >
    )
}

export default PCFinder