
import { useEffect, useState } from 'react'
import { useMediaPredicate } from 'react-media-hook'

const useMediaQuery = (args = { widthPC: 900, widthTab: 768, heightSSP: 750 }) => {
    const { widthPC, heightSSP, widthTab } = args
    const pc = useMediaPredicate(`(min-width: ${widthPC}px)`)
    const ssp = useMediaPredicate(`(max-height: ${heightSSP}px)`) && !pc

    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth)
    const tab = useMediaPredicate(`(min-width: ${widthTab}px) and (max-width:${widthPC - 1}px)`)

    useEffect(() => {
        function handleResize() {
            setIsPortrait(window.innerHeight > window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return { pc, ssp, isPortrait, tab }
}

export default useMediaQuery;