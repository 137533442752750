export const COLOR = {
  BEIGE: {
    name: 'Beige',
    color: '#C79F57',
  },
  BLACK: {
    name: 'Black',
    color: '#161616',
  },
  BLUE: {
    name: 'Blue',
    color: '#607983',
  },
  BROWN: {
    name: 'Brown',
    color: '#3D170B',
  },
  BURNT: {
    name: 'Burnt',
    color: '#892B0D',
  },
  KHAKI_DARK: {
    name: 'Dark Khaki',
    color: '#756E61',
  },
  KHAKI_LIGHT: {
    name: 'Light Khaki',
    color: '#858569',
  },
  MAROON: {
    name: 'Maroon',
    color: '#5F1206',
  },
  MATTE_BLACK: {
    name: 'Matte Black',
    color: '#0d0d0d',
  },
  ORANGE: {
    name: 'Orange',
    color: '#C65F23',
  },
  YELLOW: {
    name: 'Yellow',
    color: '#CAB43B',
  },
};

export const COLORS = [
  COLOR.BEIGE,
  COLOR.BLACK,
  COLOR.BLUE,
  COLOR.BROWN,
  COLOR.BURNT,
  COLOR.KHAKI_DARK,
  COLOR.KHAKI_LIGHT,
  COLOR.MAROON,
  COLOR.ORANGE,
  COLOR.YELLOW,
];
