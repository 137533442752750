import React from 'react'
import { animated, useSpring } from 'react-spring'
import { IMG_PATH } from '../../constants/paths'

const CrossfadeImg = ({ animationPercentage, reverse, src1, src2, alt1 = '', alt2 = '', srcBKG, className = '', style = {}, imgStyle = {}, tension = 500, friction = 50, localImg = true }) => {

    const imgOneProps = useSpring({
        opacity: reverse ? animationPercentage : 1 - animationPercentage,
        config: { tension: tension, friction: friction },
    })

    const imgTwoProps = useSpring({
        opacity: reverse ? 1 - animationPercentage : animationPercentage,
        config: { tension: tension, friction: friction },
    })

    const absoluteStyle = {
        position: 'absolute', top: 0, left: 0, height: '100%', width: '100%'
    }

    return (
        <animated.div className={className} style={{ position: 'relative', display: 'inline-block', ...style }}>
            {srcBKG && <img src={(localImg ? IMG_PATH : '') + srcBKG} style={{ ...imgStyle, ...absoluteStyle }} />}
            <animated.img src={(localImg ? IMG_PATH : '') + src1} alt={alt1} style={{ ...imgStyle, position: 'relative', ...imgOneProps }} />
            <animated.img src={(localImg ? IMG_PATH : '') + src2} alt={alt2} style={{ ...imgStyle, ...absoluteStyle, ...imgTwoProps }} />

        </animated.div>
    )
}

export default CrossfadeImg