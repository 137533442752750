import React from 'react'
import { useSpring, animated } from 'react-spring'
import AnimateIn from '../../animation/AnimateIn'
import { IMG_PATH } from '../../../constants/paths'


const IOSWatch = ({ getAnimationPercentage }) => {
    const animationPercentage = getAnimationPercentage(0, 1)
    const castAnimationPercentage = getAnimationPercentage(0.15, 0.3)
    const remoteAnimationPercentage = getAnimationPercentage(0.5, 0.6)

    const castAnimation = useSpring({
        transform: `translateX(${90 - castAnimationPercentage * 90}%) rotate(${40 - castAnimationPercentage * 40}deg)`
    })

    const remoteAnimation = useSpring({
        transform: `scale(${remoteAnimationPercentage})`
    })


    return (
        <div className='ios-watch'>
            <AnimateIn trigger={animationPercentage > 0}>
                <h2>Apple Watch Integration!</h2>
            </AnimateIn>
            <div className="ios-watch__sukusho">
                <div className="ios-watch__section ios-watch__section--remote">
                    <IOSWatchSubtext
                        title='VLC Remote'
                        txt='Control VLC running on your Mac or PC!'
                        animationPercentage={remoteAnimationPercentage}
                        className='remote'
                    />

                    <animated.img style={{ ...remoteAnimation }} className='ios-watch__remote-img' src={IMG_PATH + 'screenshots/watchos_remote_circle.png'} alt="" />

                </div>
                <div className="ios-watch__section ios-watch__section--cast">

                    <IOSWatchSubtext
                        title='Cast & Crew View'
                        txt={<>See cast and crew right from your wrist, so you don't <br />miss a moment!</>}
                        animationPercentage={castAnimationPercentage}
                        className='cast'
                    />

                    <animated.div style={{ ...castAnimation }} className="ios-watch__cast-wrapper">
                        <img className='ios-watch__cast-img' src={IMG_PATH + 'screenshots/watchos-2.png'} alt="" />
                    </animated.div>
                </div>
            </div>
        </div>
    )
}


const IOSWatchSubtext = ({ title, txt, animationPercentage, className }) => {


    const titleAnimation = useSpring({
        transform: `translateX(${-100 + animationPercentage * 100}%)`,
        opacity: animationPercentage
    })

    const txtAnimation = useSpring({
        opacity: animationPercentage >= 1 ? 1 : 0,
        delay: 200
    })


    return (

        <div className={`ios-watch__subtext ios-watch__subtext--${className}`}>
            <animated.h3 style={{ ...titleAnimation }}>{title}</animated.h3>
            <animated.p style={{ ...txtAnimation }} className='glow'>{txt}</animated.p>
        </div>



    )
}

export default IOSWatch