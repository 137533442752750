import './style/style.scss';
import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './App';

import store from './store/store';
import { render } from 'react-dom';

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

const rootElement = document.getElementById('root');
render(app, rootElement);

// if (rootElement.hasChildNodes()) {
//   hydrate(app, rootElement);
// } else {
//   render(app, rootElement);
// }
