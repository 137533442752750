import { ui } from '../../constants/redux';

const initialState = {
  autoHideMenu: true,
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case ui.AUTO_HIDE_MENU:
      return {
        ...state,
        autoHideMenu: action.payload ?? true,
      };
    default:
      return state;
  }
};
