import React, { useEffect, useState } from 'react'
import { useSpring, animated, useInView } from 'react-spring'
const AnimateIn = ({ direction = 'up', initialOffset = '6rem', stickAround = false, tension = 170, friction = 26, delay = 0, config = {}, children, opacity = 1, transform, trigger = null }) => {

    const [ref, inView] = useInView()
    const animate = trigger === null ? inView : trigger

    const [didAnimate, setDidAnimate] = useState(false)

    const stopAnimation = didAnimate && stickAround

    const animationProps = useSpring({
        opacity: animate || stopAnimation ? opacity : 0,
        transform: (transform ? transform + ' ' : '') + `translate${(direction === 'up' || direction === 'down') ? 'Y' : 'X'}(${direction === 'down' || direction === 'right' ? '-' : ''}${(animate || stopAnimation) ? '0' : initialOffset})`,
        delay,
        config: { tension, friction, ...config }
    })

    useEffect(() => {
        if (animate) setDidAnimate(true)
    }, [animate])

    const childType = children.type

    const AnimatedChildType = animated[childType]

    const childProps = { ...children.props }

    return (
        <AnimatedChildType {...childProps} style={{ ...childProps.style, ...animationProps }} ref={ref} />
    )
}

export default AnimateIn